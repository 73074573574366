import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Spin, Tabs } from "antd";
import ToolsCard from "@components/cards/ToolsCard";
import { requestGetResources } from "@state/resource/ResourceEffects";
import { File } from "@type/resource-type-interface";
import TagManager from "react-gtm-module";
import { mapResourceTofileType } from "@utils/upload-utils";
import FilesCard from "@components/files/FilesCard";
import ContactInformationsCard from "@components/cards/ContactInformationsCard";
import { HabitAPlusContactResponseDto } from "@state/gazprofessional/dto/gaz.professional.habitaplus.contact.response.dto";
import { requestGetGazProfessionalHabitAPlusContact } from "@state/gazprofessional/GazProfessionalEffects";
import toastAlert from "@components/alerts/ToastAlert";
import { Loader } from "@components/loader/Loader";

const { TabPane } = Tabs;

export const ToolsGazProfessionalScreen: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    void requestGetResources({
      dto: {
        domain: ["INSTALLATION", "MAINTENANCE", "GLOBAL", "GAZ_MEETING"],
        ids: [],
      },
    });
  }, []);

  const [installationFiles, setInstallationFiles] = useState<File[]>([]);
  const [maintenanceFiles, setMaintenanceFiles] = useState<File[]>([]);
  const [globalFiles, setGlobalFiles] = useState<File[]>([]);
  const [gazMeeting, setGazMeeting] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<HabitAPlusContactResponseDto>();

  useEffect(() => {
    return requestGetResources.done.watch(({ result }) => {
      if (result.ok && result.data && result.data.length > 0) {
        setInstallationFiles(
          result.data
            .filter((file) => file.domain === "INSTALLATION")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setMaintenanceFiles(
          result.data
            .filter((file) => file.domain === "MAINTENANCE")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setGlobalFiles(
          result.data
            .filter((file) => file.domain === "GLOBAL")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setGazMeeting(
          result.data
            .filter((file) => file.domain === "GAZ_MEETING")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
      }
      setIsLoading(false);
    });
  }, []);

  const fecthContact = useCallback(() => {
    requestGetGazProfessionalHabitAPlusContact({}).catch(() => {
      toastAlert(t<string>("pro.tools.contactInformations.error"));
    });
  }, [t]);

  useEffect(() => {
    fecthContact();
  }, [fecthContact]);

  useEffect(() => {
    return requestGetGazProfessionalHabitAPlusContact.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setContact(data);
        }
      },
    );
  });

  return (
    <PageLayout
      container
      titleCentered={false}
      title={t<string>("pro.tools.title")}
      subtitle={t<string>("pro.tools.subtitle")}
    >
      <>
        <h2 className="text-primary mt-3 mb-20">
          {t("pro.tools.contactInformations.title")}
        </h2>
        {contact == null ? (
          <Loader text={t<string>("pro.loader")} />
        ) : (
          <div className="d-flex align-items-start justify-content-center flex-wrap gap-20">
            <ContactInformationsCard
              title={`${contact.op.branch} (${contact.op.name})`}
              subtitle={t<string>("pro.tools.contactInformations.op")}
              address={contact.op.address}
              emails={contact.op.emails}
              phone={contact.op.phoneNumber}
            />
            <ContactInformationsCard
              title={contact.regionalDelegate.name}
              subtitle={t<string>("pro.tools.contactInformations.dr")}
              email={contact.regionalDelegate.email}
              phone={contact.regionalDelegate.phoneNumber}
            />
            <ContactInformationsCard
              title={t<string>(
                "pro.tools.contactInformations.habitAPlusSupport.title",
              )}
              assistanceCode={contact.assistanceCode}
              timetable={t<string>(
                "pro.tools.contactInformations.habitAPlusSupport.timetable",
              )}
              email={t<string>(
                "pro.tools.contactInformations.habitAPlusSupport.email",
              )}
              phone={t<string>(
                "pro.tools.contactInformations.habitAPlusSupport.phone",
              )}
              phoneService={t<string>(
                "pro.tools.contactInformations.habitAPlusSupport.phoneService",
              )}
            />
          </div>
        )}
        <h2 className="text-primary mt-40 mb-20">
          {t("pro.tools.practicalTools")}
        </h2>
        <div className="d-flex justify-content-center flex-wrap">
          <ToolsCard
            icon={
              <img
                alt={t<string>("pro.tools.alt.prosDuGaz")}
                src="/img/pro/prosDuGaz.svg"
              />
            }
            title={t<string>("pro.tools.prosDuGaz")}
            className="tools-prosDuGaz"
          >
            <>
              <a
                href="https://apps.apple.com/fr/app/grdf-pros-du-gaz/id1440042196"
                target="_blank"
                rel="noopener noreferrer"
                className="my-2"
              >
                <img
                  alt={t<string>("pro.tools.alt.appleStore")}
                  src="/img/pro/apple-store.png"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "clic_app_pros_du_gaz",
                      },
                    });
                  }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=fr.grdf.prosdugaz&hl=fr"
                target="_blank"
                rel="noopener noreferrer"
                className="my-2"
              >
                <img
                  alt={t<string>("pro.tools.alt.googlePlayStore")}
                  src="/img/pro/google-play-store.png"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "clic_app_pros_du_gaz",
                      },
                    });
                  }}
                />
              </a>
            </>
          </ToolsCard>
          <ToolsCard
            icon={
              <img
                alt={t<string>("pro.tools.alt.quali")}
                src="/img/pro/pdf.svg"
              />
            }
            buttonHref="https://eu1.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhB7LtvLbQx0s0tIBQS0h_wk3NcjXiKgUXkUGm2-Ec_iBQvd9gTU0EQL2oJyGfNYJqk*&hosted=false"
            buttonTarget="_blank"
            title={t<string>("pro.tools.qualVisa")}
            buttonText={t<string>("pro.tools.access")}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "clic_qual_visa",
                },
              });
            }}
          />
          <ToolsCard
            icon={
              <img
                alt={t<string>("pro.tools.alt.platform")}
                src="/img/pro/shop.svg"
              />
            }
            buttonHref="https://boutique-pg.niceshop.co"
            buttonTarget="_blank"
            title={t<string>("pro.tools.shop")}
            buttonText={t<string>("pro.tools.access")}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "clic_shop_pg",
                },
              });
            }}
          />
          <ToolsCard
            icon={
              <img
                alt={t<string>("pro.tools.alt.platform")}
                src="/img/pro/stickers.svg"
              />
            }
            buttonHref="http://boutique-lesprofessionnelsdugaz.fr/gphrbttgazvert/index.php "
            buttonTarget="_blank"
            title={t<string>("pro.tools.stickers")}
            buttonText={t<string>("pro.tools.access")}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "clic_stickers_pg",
                },
              });
            }}
          />
        </div>
        <Tabs defaultActiveKey="1" centered className="mt-40">
          <TabPane tab={t<string>("pro.tools.ressources")} key="1">
            <>
              <div className="mb-20">{t<string>("pro.tools.documents")}</div>
              {!isLoading ? (
                <div>
                  <h3 className="text-primary mb-20">
                    {t<string>("pro.tools.installation")}
                  </h3>
                  <div className="d-flex justify-content-center flex-wrap">
                    <FilesCard files={installationFiles} />
                  </div>
                  <h3 className="text-primary my-20">
                    {t<string>("pro.tools.maintenance")}
                  </h3>
                  <div className="d-flex justify-content-center flex-wrap">
                    <FilesCard files={maintenanceFiles} />
                  </div>
                </div>
              ) : (
                <div className="anticon-resource">
                  <Spin size="large" />
                </div>
              )}
            </>
          </TabPane>
          {globalFiles.length > 0 && (
            <TabPane tab={t<string>("pro.tools.reviews")} key="2">
              {!isLoading ? (
                <div className="d-flex justify-content-center flex-wrap">
                  <FilesCard files={globalFiles} />
                </div>
              ) : (
                <div className="anticon-resource">
                  <Spin size="large" />
                </div>
              )}
            </TabPane>
          )}
          {gazMeeting.length > 0 && (
            <TabPane tab={t<string>("pro.tools.gazMeeting")} key="3">
              {!isLoading ? (
                <div className="d-flex justify-content-center flex-wrap">
                  <FilesCard files={gazMeeting} />
                </div>
              ) : (
                <div className="anticon-resource">
                  <Spin size="large" />
                </div>
              )}
            </TabPane>
          )}
        </Tabs>
      </>
    </PageLayout>
  );
};
